import Vue from "vue";

export default {
    getTiposAccionesDisciplinarias(parametros) {
        return Vue.axios
            .get("catalogo/llamado_atencion_tipos/find", {params: parametros})
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },
    getTipoAccionDisciplinaria(id) {
        return Vue.axios
            .get("catalogo/llamado_atencion_tipos/" + id)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },
    addTipoAccionDisciplinaria(parametros) {
        return Vue.axios
            .post("catalogo/llamado_atencion_tipos", parametros)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },
    updateTipoAccionDisciplinaria(parametros) {
        return Vue.axios
            .put("catalogo/llamado_atencion_tipos", parametros)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },
    deleteTipoAccionDisciplinaria(id) {
        return Vue.axios
            .delete("catalogo/llamado_atencion_tipos/" + id)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },
};
